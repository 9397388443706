@import 'node_modules/@next-insurance/ni-material/styles/typography-variables-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/colors';
@import 'node_modules/@next-insurance/ni-material/styles/mixins';

.brick-payg-bubble {
  border: solid 1px var(--next-blue-10);
  vertical-align: middle;
  @include on-desktop {
    margin: 0 0 0 10px;
    display: initial;
  }
  @include status-label(green);
}
