@import '../abstracts/mixins';
@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';

.ni-brick {
  padding: var(--ni-lg-spacing);
  border-radius: $border-radius-super-curvy;

  @include on-desktop {
    border-radius: $border-radius-super-curvy-m;
    padding: var(--ni-xl-spacing);
  }

  &.ni-brick-border {
    border: 1px solid var(--gray-50);

    &:not(.not-available):hover,
    &.selected {
      border: 1px solid var(--ocean-blue);
    }

    &.not-available {
      background-color: var(--gray-10);
    }
  }
}
