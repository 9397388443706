
.p-tooltip-arrow {
  display: none;
}

.p-tooltip-text {
  font-family: $font-family-graphik;
  border-radius: 3px;
  padding: var(--ni-md-spacing);
}

.black-tooltip {
  --coverage-tooltip-width: 65vw;

  .p-tooltip-text {
    width: var(--coverage-tooltip-width);
    color: var(--white);
    background-color: var(--black);
    border: 1px solid var(--gray-80);
  }
  @include on-desktop {
    --coverage-tooltip-width: 21vw;
  }
}
