@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/mixins';

.spinner {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 4px solid var(--ocean-blue);
  animation: spinner-fast 0.8s infinite linear alternate,
    spinner-slow 1.6s infinite linear;

  @include on-desktop {
    width: 56px;
    height: 56px;
    border: 5px solid var(--ocean-blue);
  }
}

@keyframes spinner-fast {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes spinner-slow {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.spinner-wrapper {
  margin-top: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.simple-spinner {
  width: 45px;
  height: 45px;
  border: 4px solid var(--ocean-blue);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @include on-desktop {
    width: 63px;
    height: 63px;
    border: 5px solid var(--ocean-blue);
    border-bottom-color: transparent;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
