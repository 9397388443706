@import 'node_modules/@next-insurance/ni-material/styles/typography-variables-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/mixins';

body {
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: $font-family-graphik;
  font-size: $ni-md-font-size;
}

.p-md-lg {
  @include typography(
      $font-family-graphik,
      $ni-md-font-size,
      $ni-lg-line-height,
      $ni-font-weight-regular,
      $ni-lg-font-size,
      $ni-xl-line-height
  )
}
