@import 'apps/shell/styles/abstracts/mixins';
@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/typography-variables-rebrand';

ni-coverage-customization-modal {
  * {
    box-sizing: border-box;
  }

  .p-sidebar {
    background: var(--gray-20);
     color: var(--black);

    & .p-sidebar-content {
      @include on-desktop {
        padding-right: var(--ni-6xl-spacing);
        padding-bottom: var(--ni-3xl-spacing);
      }
    }

    & .p-sidebar-footer {
      padding: 0;
    }
  }

  .p-sidebar-header {
    width: 100%;
    padding: var(--ni-lgp-spacing) var(--ni-lgp-spacing) 0;
  }

  .p-sidebar-content {
    width: 100%;
    padding: 0 var(--ni-lgp-spacing) var(--ni-lgp-spacing);
  }
}
