.button-info {
  &.p-element {
    .p-button {
      color: var(--black);
      background-color: var(--gray-20);
      padding: var(--ni-sm-spacing) var(--ni-md-spacing);
      border-radius: $border-radius-super-curvy;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: var(--ni-lg-spacing);
      width: 100%;
      border: 1px solid transparent;

      @include on-desktop {
        margin-top: var(--ni-xl-spacing);
        padding: var(--ni-md-spacing) var(--ni-lg-spacing);
      }
    }

    .p-button:focus-visible {
      border: 1px solid var(--ocean-blue);
      box-shadow: 0 0 1px 1px var(--ocean-blue);
      border-radius: 12px;
      outline: 0;
    }
  }
}
