@import '../abstracts/mixins';
@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';

.single-centered-container {
  margin: 0 var(--ni-lg-spacing);

  @include on-desktop {
    width: 692px;
    margin: 0 auto;
  }

  @include on-tablet {
    width: 522px;
  }
}

.modal-layout {
  .nsm-dialog {
    justify-content: center;

    .nsm-content {
      border-radius: $border-radius-super-curvy;
      min-width: 90vw;
      padding: 0;
      margin: 0;

      @include on-desktop {
        min-width: 880px;
      }

      @include on-tablet {
        min-width: 90vw;
      }

      .nsm-body {
        width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include on-desktop {
          width: 880px;
        }

        @include on-tablet {
          width: 100%;
        }
      }
    }
  }

  .coverage-modal {
    .nsm-content {
      .nsm-body {
        width: 100vw;

        @include on-desktop {
          width: 880px;
        }

        @include on-tablet {
          width: 90vw;
        }
      }
    }

    &.mobile-full-screen .nsm-content {
      border-radius: 0;

      @include on-desktop {
        border-radius: $border-radius-super-curvy;
      }
    }
  }
}
