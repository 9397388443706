@import 'apps/shell/styles/abstracts/mixins';

.braintree-sheet__header {
  display: none;
}

.braintree-form__label {
  display: none !important;
}

.braintree-placeholder {
  display: none;
}

.braintree-form__hosted-field {
  border: none !important;
  box-shadow: none !important;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__icon-container {
  margin-top: 11px;
  position: relative;
  right: 0;
  width: 42px;
}

.braintree-sheet__content--form .braintree-form__flexible-fields {
  justify-content: flex-end;
}

.braintree-sheet__content--form .braintree-form__flexible-fields .braintree-form__field-group {
  flex-basis: 90px;

  @include on-desktop {
    flex-grow: 0 !important;
    flex-basis: 120px;
    position: relative;
  }
}

.braintree-sheet__content--form {
  padding: 0;
  display: flex;

  @include on-desktop {
    padding: 0 3px 0 10px;
  }
}

.braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--has-error .braintree-form__field-error {
  color: var(--rose);
  position: absolute;
  margin-top: 10px;
  max-width: 90px;

  @include on-desktop {
    max-width: 120px;
  }
}

.braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--is-focused .braintree-form__field-secondary-icon,
.braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--card-type-known .braintree-form__field-secondary-icon{
  transform: scale(0.65);
}

.braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--has-error .braintree-form__field-secondary-icon, .braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--has-error.braintree-form__field-group--card-type-known .braintree-form__field-secondary-icon {
  display: none !important;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field-secondary-icon {
   transform: scale(0.65);
}

div[data-braintree-id="card-number-icon"] {
  display: block !important;
}

div[data-braintree-id="number-field-group"] {
  width: 270%;
  @include on-desktop {
    width: 100%;
  }
}

div[data-braintree-id="number-field-error"] {
  max-width: 190px !important;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
  padding: 0;
}

div[data-braintree-id="cvv-field-group"] {
  flex-basis: 70px !important;
  flex-grow: 0;

  @include on-desktop {
    flex-basis: 100px !important;
  }
}

div[data-braintree-id="expiration-date-field-group"] {
  flex-basis: 70px !important;
  flex-grow: 0;

  @include on-desktop {
    flex-basis: 100px !important;
  }
}

div[data-braintree-id="toggle"] {
  display: none;
}

div[data-braintree-id="save-card-field-group"] {
  display: none;
}

.braintree-sheet__container {
  margin: 0 auto 25px;
}

.braintree-upper-container::before {
  background-color: transparent;
}

.braintree-sheet__content--form .braintree-form__field-group {
  margin-bottom: 0;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field-error-icon use {
  fill: var(--rose);
}

div[data-braintree-id="cvv-icon"] {
  display: none;
}

.braintree-sheet {
  border: 1px solid var(--gray-50);
  box-sizing: border-box;
  border-radius: 14px;
}

.braintree-hosted-fields-focused {
  border: 1px solid var(--ocean-blue);
  box-shadow: 0 0 1px 1px var(--ocean-blue);
}
