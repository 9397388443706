@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/typography-variables-rebrand';

.monthly-yearly-dropdown {
  .ng-select.ni-select .ng-select-container {
    cursor: pointer;
    border: none;
    box-shadow: none;
    width: 190px;

    @include on-desktop {
      width: 200px;
    }
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container {
    justify-content: center;
  }

  .ng-select.ni-select .ng-select-container:focus-within {
    box-shadow: none;
  }

  .ng-select.ni-select .ng-select-container .ng-arrow-wrapper {
    padding-top: var(--ni-xs-spacing);
  }

  .ng-select.ni-select.ng-select-opened.ng-select-bottom .ng-select-container {
    border: none;
    width: 190px;

    @include on-desktop {
      width: 200px;
    }
  }

  .ng-select.ni-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--next-blue-10);
  }

  .ng-select.ni-select .ng-dropdown-panel.ng-select-bottom {
    top: 50px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    .selected-payment-icon {
      display: none;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: var(--gray-10);

    .selected-payment-icon {
      display: flex;
      font-size: 18px;
      align-self: center;
    }
  }
}
