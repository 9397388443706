@import 'node_modules/ngx-toastr/toastr.css';
@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';

.ngx-toastr {
  &.toast-success {
    color: var(--black);
    background-color: var(--next-green);
    border-radius: $border-radius-super-curvy;
    box-shadow: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTUuOTk5NiIgY3k9IjE1Ljk5NzIiIHI9IjEyLjAwNSIgc3Ryb2tlPSIjMjMxRjIwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMS4yNTYzIDE2LjQ1MDNMMTQuMTQ2OSAxOS4zNDA4TDE0LjEyODIgMTkuMzIyMkwyMC42NDk2IDEyLjgwMDgiIHN0cm9rZT0iIzIzMUYyMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
  }
}

.toast-container.toast-bottom-center {
  .ngx-toastr {
    width: fit-content;
  }
}
