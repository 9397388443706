@import 'apps/shell/styles/abstracts/mixins';

html[lang="es"] {
  #purchase-button-container {
    @include on-desktop {
      width: 350px;
    }
  }

  #try-again-button {
    @include on-desktop {
      max-width: 250px;
    }
  }

  #comparative-quote-footer {
    @include on-desktop {
      max-width: 1140px;
    }
  }

  #cancel-online-container {
    max-width: 230px;
  }
}
