.information-modal {
  &.p-element {
    .p-dialog {
      padding: var(--ni-xl-spacing) var(--ni-lg-spacing) var(--ni-xl-spacing) var(--ni-lg-spacing);
      @include on-desktop {
        padding: var(--ni-2xl-spacing);

      }

      .p-dialog-header {
        padding: 0;
        margin-bottom: var(--ni-xl-spacing);
      }
    }
  }
}
