.full-screen {
  min-height: 100vh;
  overflow: hidden;
}

.invert {
  background: var(--black);
  color: var(--white);
}

.mobile-only {
  display: block;

    @include on-desktop {
      display: none;
    }
    @include on-tablet {
      display: none;
    }
}

.tablet-and-mobile-only {
  display: block;

  @include on-wide-desktop {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include on-desktop {
    display: block;
  }
  @include on-tablet {
    display: block;
  }
}
