@import 'primeicons/primeicons.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'prime-tooltip';
@import "@next-insurance/styles/scss/vendors/prime/input-switch";
@import 'prime-button';
@import 'prime-pdialog';

$overlay-background: #0e2b42b3;
$dialog-box-shadow: 0 7px 8px -4px #0003, 0 13px 19px 2px #00000024, 0 5px 24px 4px #0000001f;

* {
  box-sizing: revert;
}

.p-element {
  .p-component-overlay {
    background-color: $overlay-background;
    backdrop-filter: blur(3px);
  }

  .p-dialog {
    background-color: var(--white);
    justify-content: center;
    border-radius: $border-radius-super-curvy;
    min-width: 90vw;
    box-shadow: $dialog-box-shadow;

    @include on-desktop {
      min-width: 880px;
    }

    @include on-tablet {
      min-width: 90vw;
      max-width: 90%;
    }

    .p-dialog-header {
      padding: var(--ni-xl-spacing);
      color: var(--black);


      .p-dialog-title {
        font-family: $font-family-graphik;
        font-size: $ni-xl-font-size;
        line-height: $ni-lg-line-height;
      }

      button {
        min-width: fit-content;

        .p-dialog-header-close-icon {
          color: var(--gray-75);
        }
      }
    }

    .p-dialog-content {
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: $border-radius-super-curvy;

      @include on-desktop {
        width: 880px;
      }

      @include on-tablet {
        width: 100%;
      }
    }
  }
}

// use this class on contentStyleClass to set padding: 0 for .p-dialog-content
.reset-padding-dialog-content.p-dialog-content {
  padding: 0;
}

.full-width-dialog-content.p-dialog-content {
  align-items: normal !important;
  width: 100% !important;
}

.full-screen-mobile-modal {
  .p-dialog {
    height: 100dvh;
    width: 100vw;
    max-height: 100dvh;
    border-radius: unset;

    @include on-desktop {
      border-radius: $border-radius-super-curvy;
      width: 880px;
      height: 90vh;
    }

    .p-dialog-content {
      display: block !important;
      width: auto;
      border-radius: 0;
    }

    .p-dialog-footer {
      padding: var(--ni-lg-spacing);
      display: flex;
      flex-direction: column;

      .purchase-btn {
        @include on-desktop {
          width: 260px;
          align-self: end;
        }
      }
    }
  }
}

.footer-shadow {
  .p-dialog {
    .p-dialog-footer {
      box-shadow: 0 -1px 2px 0 rgb(0 0 0 / 6%),
      0 -1px 3px 0 rgb(0 0 0 / 10%);
      z-index: 100;
    }
  }
}

.header-shadow {
  .p-dialog {
    .p-dialog-header {
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 6%),
      0 1px 3px 0 rgb(0 0 0 / 10%);
      z-index: 100;
    }
  }
}

// Use this class to set the color of the new modal design overlay
.pure-black-opacity-50-bg.p-element .p-component-overlay-enter {
  animation: overlay-color 150ms forwards;
  backdrop-filter: none;

  @keyframes overlay-color {
    0% {
      background-color: transparent;
    }

    100% {
      background-color: var(--pure-black-opacity-50);
    }
  }
}


// Next style for p-menu
.p-menu-overlay {
  border-radius: $border-radius-super-curvy;
  border: 1px solid var(--gray-50);
  top: 45px !important;
  left: -65px !important;

  .p-menuitem {
    font-size: var(--ni-md-font-size);
    line-height: var(--ni-lg-line-height);
    font-family: $font-family-graphik;
    border-radius: $border-radius-super-curvy;

    .p-menuitem-icon {
      padding-right: var(--ni-sm-spacing);
      color: var(--black);
    }

    .p-menuitem-content {
      padding: var(--ni-md-spacing);

      &:hover {
        background: var(--gray-25);
      }
    }
  }

  li:first-child {
    .p-menuitem-content {
      border-radius: $border-radius-super-curvy $border-radius-super-curvy 0 0;

    }
  }

  li:last-child {
    .p-menuitem-content {
      border-radius: 0 0 $border-radius-super-curvy $border-radius-super-curvy;

      .p-menuitem-icon {
        color: var(--rose);
      }

      .p-menuitem-text {
        color: var(--rose);
      }
    }
  }
}
