/* Private variables */
$desktop-content-max-width: 835px;
$desktop-content-padding-lr: calc((#{$desktop-content-max-width} - 815px) / 2);
$mobile-content-max-width: 365px;
$mobile-content-padding-lr: calc((#{$mobile-content-max-width} - 345px) / 2);

.ni-content-container {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 0 60px;
  max-width: $mobile-content-max-width;
  padding-left: $mobile-content-padding-lr;
  padding-right: $mobile-content-padding-lr;
}

@include on-desktop {
  .ni-content-container {
    max-width: $desktop-content-max-width;
    padding-left: $desktop-content-padding-lr;
    padding-right: $desktop-content-padding-lr;
  }
}
